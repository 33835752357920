// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
// import Img from 'gatsby-image'

import compose from 'recompose/compose'
import omit from 'lodash/omit'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
// import MediaQuery from 'react-responsive'

// import Gallery from 'react-photo-gallery'
import GalleryX from '@bodhi-project/components/lib/gatsby/Gallery'

import clientDetection from '@bodhi-project/components/lib/extensions/client-detection'
import mobileDetection from '@bodhi-project/components/lib/extensions/mobile-detection'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
/** [description] */
const ResponsiveGallery = props => {
  const { isMobile, conf = {} } = props
  const max = isMobile === true ? 4 : undefined

  return <GalleryX {...omit(props, ['conf'])} conf={{ ...conf, max }} />
}

/** Compose ala FP style */
const ComposedResponsiveGallery = compose(
  clientDetection, // Add client detection
  mobileDetection // Add mobile detection
)(ResponsiveGallery)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default ComposedResponsiveGallery
