// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import map from 'lodash/map'
import isNull from 'lodash/isNull'
import slice from 'lodash/slice'

import { compiler } from 'markdown-to-jsx'
import compose from 'recompose/compose'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ State
import { connect } from 'react-redux'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import ConfigProvider from 'antd/lib/config-provider'
import '@bodhi-project/antrd/lib/4.10.3/config-provider/style/css'

import Division from '@bodhi-project/components/lib/division'
import MediaQuery from 'react-responsive'

import Collapse from 'antd/lib/collapse'
import '@bodhi-project/antrd/lib/4.10.3/collapse/style/css'

import Row from 'antd/lib/row'
import '@bodhi-project/antrd/lib/4.10.3/row/style/css'

import Col from 'antd/lib/col'
import '@bodhi-project/antrd/lib/4.10.3/col/style/css'

import Gallery from 'react-photo-gallery'
import GalleryX from '@bodhi-project/components/lib/gatsby/Gallery'

import Video from '@bodhi-project/components/lib/video'
import '@bodhi-project/components/lib/video/style.less'

import getRandomArbitraryInt from '@bodhi-project/components/lib/methods/getRandomArbitraryInt'
import smallKey from '@bodhi-project/components/lib/methods/smallKey'

import ArrowLeft from '@bodhi-project/components/lib/svg/arrow-left'
import ArrowRight from '@bodhi-project/components/lib/svg/arrow-right'

import Carousel from 'antd/lib/carousel'
import '@bodhi-project/antrd/lib/4.10.3/carousel/style/css'

import { GatsbyImage, getImage } from 'gatsby-plugin-image'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Link from '../components/link'
import ResponsiveGallery from '../components/ResponsiveGallery'

import StandardPageWrapper from '../components/standard-page-wrapper'
import '../components/standard-page-wrapper/style.less'

import seoHelper from '../methods/seoHelper'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React
const { Panel } = Collapse
const pageData = {
  pageTitle: 'About us',
  nakedPageSlug: 'about',
  pageAbstract:
    'LiGHT-FiSH is an emerging Product Design studio focused on the minimalistic beauty of natural materials. Sustainable materials are carefully chosen and crafted into visionary and intelligently designed products. Many custom and limited edition designs are prototyped, tested and refined, each time telling a unique story. Much like the stages of growth of a plant, from seed to tree, LiGHT-FiSH chooses a design process that is organic and systematic.',
  cover: '/banners/ogX2.jpg',
}

const seoData = seoHelper(pageData)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    allResources(
      sort: { fields: [publishedAt], order: ASC }
      filter: { slug: { regex: "//notes//" } }
    ) {
      edges {
        node {
          title
          abstract
          contentBlocks {
            type
            content
            resources {
              resource {
                childImageSharp {
                  gatsbyImageData(
                    width: 600
                    placeholder: TRACED_SVG
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
          hasCategories
        }
      }
    }
    logoLight: file(relativePath: { eq: "logos/logo-light.png" }) {
      ...max1600
    }
    img11: file(relativePath: { eq: "about/img1.jpg" }) {
      ...max600
    }
    img12: file(relativePath: { eq: "about/img2.jpg" }) {
      ...max600
    }
    img13: file(relativePath: { eq: "about/img3.jpg" }) {
      ...max600
    }
    img14: file(relativePath: { eq: "about/img4.jpg" }) {
      ...max600
    }
    img15: file(relativePath: { eq: "about/img5.jpg" }) {
      ...max600
    }
    img16: file(relativePath: { eq: "about/img6.jpg" }) {
      ...max600
    }
    img21: file(relativePath: { eq: "about/img7.jpg" }) {
      ...max600
    }
    img22: file(relativePath: { eq: "about/img8.jpg" }) {
      ...max600
    }
    img23: file(relativePath: { eq: "about/img9.jpg" }) {
      ...max600
    }
    img24: file(relativePath: { eq: "about/img10.jpg" }) {
      ...max600
    }
    img34: file(relativePath: { eq: "about/img14.jpg" }) {
      ...max600
    }
    img31: file(relativePath: { eq: "about/img11.jpg" }) {
      ...max600
    }
    img36: file(relativePath: { eq: "about/img12.jpg" }) {
      ...max600
    }
    img35: file(relativePath: { eq: "about/img13.jpg" }) {
      ...max600
    }
    samvit: file(relativePath: { eq: "about-us/samvit.jpg" }) {
      ...max600
    }
    img1: file(relativePath: { eq: "about-us/slider/img1.jpg" }) {
      ...max1600
    }
    teamPhoto1: file(relativePath: { eq: "about-us/team/img1.jpg" }) {
      ...max600
    }
    teamPhoto2: file(relativePath: { eq: "about-us/team/img2.jpg" }) {
      ...max600
    }
    teamPhoto3: file(relativePath: { eq: "about-us/team/img3.jpg" }) {
      ...max600
    }
    teamPhoto4: file(relativePath: { eq: "about-us/team/img5.jpg" }) {
      ...max600
    }
    teamPhoto5: file(relativePath: { eq: "about-us/team/img6.jpg" }) {
      ...max600
    }
    teamPhoto6: file(relativePath: { eq: "about-us/team/img7.jpg" }) {
      ...max600
    }
    teamPhoto7: file(relativePath: { eq: "about-us/team/img8.jpg" }) {
      ...max600
    }
    teamPhoto8: file(relativePath: { eq: "about-us/team/img9.jpg" }) {
      ...max600
    }
    teamPhoto9: file(relativePath: { eq: "about-us/team/img10.jpg" }) {
      ...max600
    }
    teamPhoto10: file(relativePath: { eq: "about-us/team/img11.jpg" }) {
      ...max600
    }
    teamPhoto11: file(relativePath: { eq: "about-us/team/img12.jpg" }) {
      ...max600
    }
    teamPhoto12: file(relativePath: { eq: "about-us/team/img13.jpg" }) {
      ...max600
    }
    teamPhoto13: file(relativePath: { eq: "about-us/team/img14.jpg" }) {
      ...max600
    }
    teamPhoto14: file(relativePath: { eq: "about-us/team/img15.jpg" }) {
      ...max600
    }
    teamPhoto15: file(relativePath: { eq: "about-us/team/img16.jpg" }) {
      ...max600
    }
    teamPhoto16: file(relativePath: { eq: "about-us/team/img17.jpg" }) {
      ...max600
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = (props) => {
  const {
    data: {
      allResources: { edges: resourcesX },
    },
    mediaState: { currentWidth = 'isDesktop' } = {},
  } = props

  const isSmallScreen =
    currentWidth === 'isMobile' || currentWidth === 'isTablet'

  let resources = []
  map(resourcesX, (resource) => {
    resources.push(resource.node)
  })

  // if (isSmallScreen === true) {
  //   resources = slice(resources, 0, 4)
  // }

  const sliderConf = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: false,
    vertical: true,
    arrows: true,
    prevArrow: <ArrowLeft />,
    nextArrow: <ArrowRight />,
  }

  /** imageRenderer */
  const imageRenderer = ({ index, onClick, photo, margin }) => {
    const image = getImage(photo.data)
    return (
      <GatsbyImage
        style={{ width: photo.width, height: photo.height, margin: '2px' }}
        image={image}
      />
    )
  }

  return (
    <ConfigProvider prefixCls="wtf">
      <StandardPageWrapper className="about-page" seoData={seoData} {...props}>
        <div className="intro">
          <Img
            fluid={props.data.img1.childImageSharp.fluid}
            durationFadeIn={getRandomArbitraryInt(300, 1200)}
          />
          <div className="blah">
            <h1>
              <span>
                WE PRIDE OURSELVES ON OUR <strong>‘FACILE MONTI’</strong> STYLE
                OF <strong>CONSTRUCTION</strong>
              </span>
            </h1>
          </div>
        </div>
        <div className="block left" style={{ display: 'flex' }}>
          <div
            style={{
              flex: '1',
              alignItems: 'top',
              justifyContent: 'center',
              display: 'flex',
              paddingRight: '1rem',
            }}
            className="hidden-on-tablet hidden-on-mobile"
          >
            <Img
              fluid={props.data.samvit.childImageSharp.fluid}
              durationFadeIn={getRandomArbitraryInt(300, 1200)}
              style={{ width: '62%' }}
            />
          </div>
          <div style={{ flex: '1', alignItems: 'center', display: 'flex' }}>
            <div>
              <h2>LiGHT-FiSH is a unit of Auroville</h2>
              <p style={{ maxWidth: '48rem' }}>
                <big>
                  LiGHT-FiSH is an emerging product design studio focused on the
                  minimalistic beauty of natural materials. Sustainable
                  materials are carefully chosen and crafted into visionary and
                  intelligently designed products. Many custom and limited
                  edition designs are prototyped, tested and refined, each time
                  telling a unique story.
                </big>
              </p>
              <p>
                LiGHT-FiSH is created by Samvit Blass. Born in Auroville, India,
                he studied Industrial Design at the Massachusett College of Art
                in the United States. After working for six years in the US, he
                taught Design at Raffles University, first in Shanghai, then in
                Mumbai. In 2009 Samvit, together with his brother Rishi and a
                handful of alumni started LiGHT- FiSH in Mumbai.
              </p>
              <p>
                <big>
                  <big>
                    <i>"Our Vision is to lead Sustainable Design in India."</i>
                  </big>
                </big>
              </p>
              <p style={{ marginBottom: 0 }}>
                In 2010 LiGHT-FiSH moved to Auroville where their competencies
                in design and development brought on bigger and more challenging
                projects. "With more space to work, we purchased hi-tech
                machinery and developed skills in working with glass, metal,
                wood, resins and plastics.
              </p>
            </div>
          </div>
        </div>
        <div className="block right">
          <h2>Regenerative Design with Kamataru Forest</h2>
          <p>
            <big>
              Our products are designed to have a positive impact on society and
              the environment. New technology is embraced to create products
              that are long lasting and efficient.
            </big>
          </p>
          <Video
            className="as-paragraph"
            style={{ maxWidth: 'unset' }}
            ratio={0.54}
            url="https://vimeo.com/162349935"
          />
          <div
            style={{ display: 'var(--responsive-flex)', maxWidth: 'unset' }}
            className="as-paragraph"
          >
            <div style={{ flex: '1', paddingRight: '1rem' }}>
              <p>
                We, at LiGHT-FiSH, virtually outsource nothing and ensure that
                nothing goes to waste. We believe in sustainable resources and
                are actively involved in preserving the environment. Rishi, the
                founder of{' '}
                <Link to="https://kamataru.weebly.com/">
                  Kamataru Forest project
                </Link>
                , selects for LiGHT-FISH wood coming only from naturally fallen
                trees.
              </p>
            </div>
            <div style={{ flex: '1' }}>
              <p style={{ marginBottom: 0 }}>
                LiGHT-FiSH products are manufactured with great care in
                Auroville, an international community with a vision to bridge
                the past to a brighter, more sustainable future. The sawdust is
                returned to Kamataru and transformed into mulch and compost for
                the forest, and a full ecological cycle is completed.
              </p>
            </div>
          </div>
        </div>
        <div className="block left">
          <h2>Our Collaborators</h2>
          <Carousel {...sliderConf}>
            <div>
              <Division Row={Row} Col={Col}>
                <div
                  className="hidden-on-mobile hidden-on-tablet"
                  style={{ height: '100%' }}
                >
                  <Img
                    fluid={props.data.img35.childImageSharp.fluid}
                    durationFadeIn={getRandomArbitraryInt(300, 1200)}
                    style={{ height: '100%' }}
                  />
                </div>
                <Fragment>
                  <Img
                    fluid={props.data.img34.childImageSharp.fluid}
                    durationFadeIn={getRandomArbitraryInt(300, 1200)}
                    className="as-paragraph"
                  />
                  <h3>Vyom</h3>
                  <p>
                    Deepak, VYOM's owner, met Samvit as he was looking for a
                    designer to help him create a new brand of furniture with
                    original designs. Deepak and Samvit’s very satisfactory
                    collaboration grew into offering mutual support, sharing
                    contacts and resources. Deepak would ask for help for
                    specific projects; Samvit would help him work with other
                    furniture producers in Auroville; Deepak would help with the
                    production of large pieces like the chandeliers by letting
                    Samvit use his workshop, or he would invite him to events
                    and shows in Bangalore. Trust and appreciation allowed for a
                    true friendship to grow between them.
                  </p>
                  <p>
                    <Link to="https://www.vyomdesign.in/">
                      https://www.vyomdesign.in/
                    </Link>
                  </p>
                </Fragment>
              </Division>
            </div>
            <div>
              <Division Row={Row} Col={Col} goldenAlt>
                <div
                  className="hidden-on-mobile hidden-on-tablet"
                  style={{ height: '100%' }}
                >
                  <Img
                    fluid={props.data.img23.childImageSharp.fluid}
                    durationFadeIn={getRandomArbitraryInt(300, 1200)}
                    style={{ height: '50%' }}
                    className="as-paragraph"
                  />
                  <Img
                    fluid={props.data.img24.childImageSharp.fluid}
                    durationFadeIn={getRandomArbitraryInt(300, 1200)}
                    style={{ height: '50%', marginBottom: 0 }}
                    className="as-paragraph"
                  />
                </div>
                <Fragment>
                  <Img
                    fluid={props.data.img21.childImageSharp.fluid}
                    durationFadeIn={getRandomArbitraryInt(300, 1200)}
                    className="as-paragraph"
                  />
                  <h3>Auro Design</h3>
                  <p>
                    William, an architect, started the Educative Toys project,
                    based on handicraft carpentry. After he passed away, his
                    friend Dave decided to respect William’s expressed wish to
                    continue the project. However, in order to be able to sell
                    those toys in the US, better finished products than the
                    original models were necessary and Dave approached Samvit.
                  </p>
                </Fragment>
              </Division>
            </div>
            <div>
              <Division Row={Row} Col={Col}>
                <Fragment>
                  <Img
                    fluid={props.data.img34.childImageSharp.fluid}
                    durationFadeIn={getRandomArbitraryInt(300, 1200)}
                    className="as-paragraph"
                  />
                  <h3>Keri Studio</h3>
                  <p>
                    Before becoming a collaborator, Manan, Keri Studio’s owner,
                    came to LiGHT-FiSH as a client. Through the process of
                    developing the Conscious Light, their intense exchanges made
                    them both appreciate each other’s talents. A friendship grew
                    between them and both decided to become partners. Thus the
                    Conscious Light was born, a sensible connected lighting
                    consciously caring for our environment, result of a most
                    open and sincere collaboration.
                  </p>
                  <p>
                    <Link to="https://mjgandhi.com/">
                      https://mjgandhi.com/
                    </Link>
                  </p>
                </Fragment>
                <div
                  className="hidden-on-mobile hidden-on-tablet"
                  style={{ height: '100%' }}
                >
                  <Img
                    fluid={props.data.img35.childImageSharp.fluid}
                    durationFadeIn={getRandomArbitraryInt(300, 1200)}
                    style={{ height: '100%' }}
                  />
                </div>
              </Division>
            </div>
            <div>
              <Division Row={Row} Col={Col} golden>
                <Fragment>
                  <Img
                    fluid={props.data.img21.childImageSharp.fluid}
                    durationFadeIn={getRandomArbitraryInt(300, 1200)}
                    className="as-paragraph"
                  />
                  <h3>Functional Forms</h3>
                  <p>
                    Functional Form is an architecture and design firm based in
                    Auroville with Shama Dalvi as its lead architect. Shama
                    works in collaboration with LiGHT- FiSH for the design and
                    fabrication of a range of customized products for the firm’s
                    interior projects.
                  </p>
                  <p>
                    <Link to="https://www.functionalforms.in/">
                      https://www.functionalforms.in/
                    </Link>
                  </p>
                </Fragment>
                <div
                  className="hidden-on-mobile hidden-on-tablet"
                  style={{ height: '100%' }}
                >
                  <Img
                    fluid={props.data.img23.childImageSharp.fluid}
                    durationFadeIn={getRandomArbitraryInt(300, 1200)}
                    style={{ height: '50%' }}
                    className="as-paragraph"
                  />
                  <Img
                    fluid={props.data.img24.childImageSharp.fluid}
                    durationFadeIn={getRandomArbitraryInt(300, 1200)}
                    style={{ height: '50%', marginBottom: 0 }}
                    className="as-paragraph"
                  />
                </div>
              </Division>
            </div>
          </Carousel>
        </div>
        <div className="block right hidden-on-tablet hidden-on-mobile">
          <h2>Working and Learning at LiGHT-FiSH</h2>
          <p style={{ maxWidth: '48rem', marginBottom: '1.86rem' }}>
            <big>
              LiGHT-FiSH has a rich and diverse team of individuals in the core
              team. We also offer internship and apprenticeship opportunities
              for learners, and enjoy diverse and interesting collaborations.
            </big>
          </p>
          <GalleryX
            data={props.data}
            lookup="teamPhoto"
            columns={{ min: 3, max: 3 }}
            Img={Img}
            Gallery={Gallery}
            MediaQuery={MediaQuery}
            conf={{ shuffle: true }}
          />
        </div>
        <div className="block left">
          <h2>Design and innovation at LiGHT-FiSH</h2>
          <p style={{ maxWidth: '48rem', marginBottom: '1.86rem' }}>
            <big>
              At LiGHT-FiSH we understand what it takes to create new products
              and customize them according to our clients’ needs. All stages of
              development and prototyping processes are done end to end at our
              state of the art production facility, where prototypes are tested,
              manufactured using our Lathe, CNC, Laser machine or 3D printer and
              brought to their final look and finish. Our clientele includes
              hotels, bars, perfumeries, jewellers, private institutions, music
              festivals, artists, homes and many more. Our products range from
              delicate jewellery to massive metal sculptures. Many custom and
              limited edition designs are prototyped, tested and refined, each
              time telling a unique story.
            </big>
          </p>
          <Collapse accordion className="notes">
            {map(resources, (resource, index) => (
              <Panel
                showArrow={false}
                key={smallKey()}
                header={
                  <div>
                    <h4 style={{ maxWidth: '42rem' }}>
                      #{index + 1}. {resource.title}
                    </h4>
                    <p style={{ maxWidth: '42rem', marginBottom: 0 }}>
                      {resource.abstract}
                      {isNull(resource.contentBlocks)
                        ? ''
                        : ' Click to see more...'}
                    </p>
                  </div>
                }
                collapsible={
                  isNull(resource.contentBlocks) === true
                    ? 'disabled'
                    : 'header'
                }
              >
                {map(resource.contentBlocks, (block) => {
                  const {
                    type,
                    subType = '',
                    content,
                    resources: resourcesX,
                    resource: resourceX,
                    caption,
                  } = block
                  let returnThis = <Fragment />

                  if (type === 'space') {
                    returnThis = <br />
                  }

                  if (type === 'text') {
                    returnThis = (
                      <p style={{ maxWidth: '42rem' }}>
                        {compiler(content, { wrapper: null })}
                      </p>
                    )
                  }

                  if (type === 'gallery') {
                    const images = []
                    resourcesX.map(({ resource: resourceY }) => {
                      images.push({
                        src:
                          resourceY.childImageSharp.gatsbyImageData.images
                            .fallback.src,
                        height:
                          resourceY.childImageSharp.gatsbyImageData.height,
                        width: resourceY.childImageSharp.gatsbyImageData.width,
                        data: resourceY,
                      })
                    })

                    returnThis = (
                      <div
                        className="as-paragraph"
                        style={{ maxWidth: 'unset', width: '100%' }}
                      >
                        <Gallery
                          photos={images}
                          columns="3"
                          renderImage={imageRenderer}
                        />
                      </div>
                    )
                  }

                  if (type === 'video') {
                    returnThis = (
                      <Video
                        className="as-paragraph"
                        style={{ maxWidth: 'unset' }}
                        ratio={0.56}
                        url={content}
                      />
                    )
                  }

                  if (type === 'strong') {
                    returnThis = (
                      <h3 style={{ maxWidth: '42rem' }}>{content}</h3>
                    )
                  }

                  if (type === 'image') {
                    const image = getImage(resource)
                    const isPortrait = subType === 'portrait' ? 'portrait' : ''

                    if (isNull(caption) === false) {
                      returnThis = (
                        <div
                          className="with-caption as-paragraph"
                          style={{ maxWidth: '42rem' }}
                        >
                          <GatsbyImage
                            className={`${isPortrait}`}
                            image={image}
                          />
                          <p className="caption" style={{ maxWidth: '42rem' }}>
                            {caption}
                          </p>
                        </div>
                      )
                    } else {
                      returnThis = (
                        <GatsbyImage
                          className={`cover as-paragraph ${isPortrait}`}
                          image={image}
                          style={{ maxWidth: '42rem' }}
                        />
                      )
                    }
                  }

                  return returnThis
                })}
              </Panel>
            ))}
          </Collapse>
        </div>
      </StandardPageWrapper>
    </ConfigProvider>
  )
}

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    mediaState: state.mediaState,
  })
  // (dispatch) => ({

  // })
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedPage = compose(
  withState // Add state
)(Page)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default ComposedPage
